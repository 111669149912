import React, { useEffect, useState } from 'react';
import EnquireNowForm from '../common/EnquireNowForm';
import { API_URL } from '../../../Config';

const Form = ({campaignId}) => {
    const [dynamicInputs, setDynamicInputs] = useState();
    const [customInitialValues, setCustomInitialValues] = useState("");
   
    useEffect(() =>{
        fetch(API_URL+'atum/conceptHierarchy/list/enquiryrequirements')
        .then(response => { 
            if(!response.ok){throw new Error("Network response was not ok")} 
            return response.json();
        })
        .then(data => {
            var dynamicInititalValues = { name: '', email: '', phone: '', state: '', district: '', city: '', pincode: '',  campaign_id: campaignId };
            const conceptStages = data.data.response_data.concept_hierarchy_list.concept_stages_list;
            conceptStages.forEach((fieldKeys) => {
                dynamicInititalValues[fieldKeys.ch_stage_id] = "";
            });
            setCustomInitialValues(dynamicInititalValues);
            setDynamicInputs(conceptStages);
        })
        .catch(error =>{ console.log(error); });
    }, [campaignId]);

    return (
        <div>
            <section className="bg-white22 py-1">
                <div className="container">
                    <div className="row">
                        { (customInitialValues !== "") && <EnquireNowForm dynamicInputs={dynamicInputs} customInitialValues={customInitialValues} campaignId={campaignId} /> }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Form